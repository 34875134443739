<template>
  <div id="top">
    <v-app id="home">
      <navbar :pageIn="1" />
      <v-btn class="float" rounded icon @click="$vuetify.goTo('#top')">
        <v-icon> mdi-arrow-up </v-icon>
      </v-btn>
      <v-main>
        <v-row class="px-10">
          <v-col cols="12" md="12">
            <v-card class="mx-auto">
              <v-img
                class="white--text align-end"
                height="80px"
                src="https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FAssets%2Fabstract-colorful-geometric-overlapping-background-and-texture-free-vector.jpg?alt=media&token=d135c880-0f7a-4be7-84ed-8a2d63a9efb5"
              >
                <v-card-title class="card-title-text" style="color: gray"
                  >REGISTRO DE CITAS</v-card-title
                >
              </v-img>

              <v-data-table
                :headers="citasHeaders"
                :items="datosCitas"
                :items-per-page="5"
                class="elevation-1"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon @click="showDetails(item)">
                    mdi-file-document
                  </v-icon>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
          <v-col cols="12" md="12">
            <v-card class="mx-auto">
              <v-img
                class="white--text align-end"
                height="80px"
                src="https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FAssets%2Fabstract-colorful-geometric-overlapping-background-and-texture-free-vector.jpg?alt=media&token=d135c880-0f7a-4be7-84ed-8a2d63a9efb5"
              >
                <v-card-title class="card-title-text" style="color: gray"
                  >REGISTRO DE EVENTOS</v-card-title
                >
              </v-img>

              <v-data-table
                :headers="headers"
                :items="datosFechas"
                :items-per-page="5"
                class="elevation-1"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon @click="showDetails(item)">
                    mdi-file-document
                  </v-icon>
                  <v-icon
                    @click="updateAproved(item)"
                    color="green"
                    v-if="item.status != null"
                  >
                    mdi-check-circle
                  </v-icon>
                  <v-icon
                    @click="updateUnaproved(item)"
                    color="red"
                    v-if="item.status != null"
                  >
                    mdi-close-circle
                  </v-icon>
                </template>
                <template v-slot:[`item.tempFecha`]="{ item }">
                  <span> {{ item.tempFecha }} </span>
                  <v-chip
                    class="ma-2"
                    :color="item.statusColor"
                    text-color="white"
                    v-if="item.status != null"
                  >
                    {{ item.status }}
                  </v-chip>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
          <!-- <v-col cols="12" md="4">
            <v-card
              class="mx-auto"
            >
              <v-img
                class="white--text align-end"
                height="80px"
                src="https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FAssets%2Fdepositphotos_2833063-stock-photo-sky-after-sunset-background.jpg?alt=media&token=150a8b35-46eb-40aa-aca7-b55165e22c5f"
              >
                <v-card-title class="card-title-text">DESCARGAR PROXIMOS 5</v-card-title>
              </v-img>

              <v-card-subtitle class="pb-0">
                Si quieres tener a la mano la informacion de los siguientes eventos puedes descargarla en PDF
              </v-card-subtitle>

              <v-row justify="center" class="my-2">
                <v-col cols="10">
                  <v-btn outlined color="orange">DESCARGAR</v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-col> -->
        </v-row>
        <v-row class="px-10">
          <v-col cols="12" md="6">
            <v-card class="mx-auto">
              <v-img
                class="white--text align-end"
                height="100px"
                src="https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FAssets%2Fsub-category-1.jpg?alt=media&token=7ace2040-a226-4956-b0e1-804b82342616"
              >
                <v-card-title class="card-title-text"
                  >APARTAR UNA FECHA</v-card-title
                >
              </v-img>

              <v-card-subtitle class="pb-0">
                ¡Recuerda que apartar una fecha significa deshabilitarla en el
                calendario de reservaciones, no apartes la fecha si el cliente
                no se ve seguro!
              </v-card-subtitle>

              <v-row justify="center">
                <v-col cols="11" md="4">
                  <v-text-field
                    outlined
                    dense
                    label="Fecha"
                    type="date"
                    v-model="fechaApartar"
                  ></v-text-field>
                </v-col>
                <v-col cols="11" md="4">
                  <v-text-field
                    outlined
                    dense
                    label="Nombre"
                    v-model="nombreReserva"
                  ></v-text-field>
                </v-col>
                <v-col cols="11" md="2">
                  <v-select
                    outlined
                    dense
                    label="Pago"
                    :items="tipoPagos"
                    v-model="tipoPagoReserva"
                  ></v-select>
                </v-col>
                <v-col cols="11" md="8">
                  <v-select
                    outlined
                    dense
                    chips
                    v-model="serviciosApartar"
                    :items="itemServicios"
                    label="Servicios"
                    multiple
                  ></v-select>
                </v-col>
              </v-row>

              <v-card-actions>
                <v-btn color="red" text @click="clearFechaApartar()">
                  cancelar
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="green" text @click="apartarFecha()">
                  apartar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col cols="12" md="6">
            <v-card class="mx-auto">
              <v-img
                class="white--text align-end"
                height="100px"
                src="https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FAssets%2Fmixkit-multicolor-ink-swirls-in-water-286-0.webp?alt=media&token=ef6f744d-8f96-45bb-aca4-ed7c9116fd61"
              >
                <v-card-title class="card-title-text"
                  >CANCELAR RESERVACION</v-card-title
                >
              </v-img>

              <v-card-subtitle class="pb-0">
                ¡Cancelar una reservación hará que la fecha vuelva a estar
                disponible en el calendario, ten cuidado de no liberar una fecha
                equivocada!
              </v-card-subtitle>

              <v-row justify="center" class="my-8">
                <v-col cols="10">
                  <v-text-field
                    outlined
                    dense
                    label="Fecha"
                    type="date"
                    v-model="fechaLiberar"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-card-actions>
                <v-btn color="red" text @click="fechaLiberar = ''">
                  cancelar
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="green" text @click="liberarFecha()">
                  Liberar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>

        <v-row class="px-10">
          <v-col cols="12" md="12">
            <v-card class="mx-auto">
              <v-img
                class="white--text align-end"
                height="80px"
                src="https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FAssets%2Fimages.jpg?alt=media&token=1620e33b-3b5a-4b37-94ae-d0750460bb0a"
              >
                <v-card-title class="card-title-text"
                  >GENERAR UN CONTRATO</v-card-title
                >
              </v-img>

              <v-card-subtitle class="pb-0"> </v-card-subtitle>

              <v-form v-model="valid">
                <v-container>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        v-model="firstname"
                        :rules="nameRules"
                        :counter="50"
                        label="Nombre completo"
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        v-model="claveElector"
                        :rules="claveRules"
                        label="Clave de elector"
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        v-model="direccion"
                        :rules="direccionRules"
                        label="Direccion"
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        v-model="email"
                        :rules="emailRules"
                        label="Correo electronico"
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        v-model="reservationDay"
                        label="Fecha del evento"
                        type="date"
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        v-model="totalPrecio"
                        label="Costo"
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-btn text @click="setPago()">
                        <v-checkbox disabled v-model="pagarEntero">
                        </v-checkbox>
                        Liquidar Monto Completo
                      </v-btn>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-btn text @click="setPago()">
                        <v-checkbox disabled v-model="pagarMitad"> </v-checkbox>
                        Pagar el 50% como anticipo
                      </v-btn>
                    </v-col>

                    <v-col class="my-5" cols="12">
                      <v-btn color="orange" outlined @click="validateInfo()">
                        Generar Contrato!
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card>
          </v-col>
        </v-row>

        <v-row class="px-10">
          <v-col cols="12" md="12">
            <v-card class="mx-auto">
              <v-img
                class="white--text align-end"
                height="80px"
                src="https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FAssets%2Fabstract-colorful-geometric-overlapping-background-and-texture-free-vector.jpg?alt=media&token=d135c880-0f7a-4be7-84ed-8a2d63a9efb5"
              >
                <v-card-title class="card-title-text" style="color: gray"
                  >SOLICITUDES MARKETPLACE</v-card-title
                >
              </v-img>

              <v-data-table
                :headers="headers2"
                :items="datosMarket"
                :items-per-page="5"
                class="elevation-1"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon @click="showServicios(item.servicios)">
                    mdi-file-document
                  </v-icon>
                  <v-icon @click="showImages(item.imagenes)">
                    mdi-image-multiple
                  </v-icon>
                  <v-icon @click="showLocation(item.googleMaps)">
                    mdi-map-marker
                  </v-icon>
                </template>
                <template v-slot:[`item.aprovado`]="{ item }">
                  <v-switch
                    v-model="item.aprovado"
                    @click="aproveMarket(item)"
                  ></v-switch>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>

        <div class="my-15"></div>
      </v-main>

      <template>
        <v-row justify="center">
          <v-dialog v-model="noUserDialog" persistent max-width="290">
            <v-card>
              <v-card-title class="justify-center text-h5 mb-2">
                NO TAN RAPIDO
              </v-card-title>
              <v-icon color="#1B4332" size="80" class="mb-4">
                mdi-close
              </v-icon>
              <v-card-text
                >Antes de seguir deberias tener una cuenta, tranquilo es gratis!
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="green darken-1"
                  text
                  @click="noUserDialog = false"
                >
                  Ok
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>
      <template>
        <v-row justify="center">
          <v-dialog
            v-model="dialogServiciosMarket"
            scrollable
            max-width="300px"
          >
            <v-card>
              <v-card-title>Servicios</v-card-title>
              <v-divider></v-divider>
              <v-card-text style="height: 300px">
                <div v-if="dataServiciosMarket" class="mt-2">
                  <div v-for="(index, i) in dataServiciosMarket" :key="i">
                    <p>{{ index }}</p>
                  </div>
                </div>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialogServiciosMarket = false"
                >
                  Cerrar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>
      <template>
        <v-row justify="center">
          <v-dialog v-model="dialogUbicacion" scrollable max-width="800px">
            <v-card>
              <v-card-title>Ubicacion</v-card-title>
              <v-divider></v-divider>
              <v-card-text style="height: 300px">
                <div v-if="ubicacion">
                  <iframe
                    :src="ubicacion"
                    style="border: 0"
                    allowfullscreen=""
                    loading="lazy"
                    class="google-map"
                  >
                  </iframe>
                </div>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialogUbicacion = false"
                >
                  Cerrar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>
      <template>
        <v-row justify="center">
          <v-dialog v-model="dialogImagenesMarket" scrollable max-width="800px">
            <v-card>
              <v-card-title>Imagenes</v-card-title>
              <v-divider></v-divider>
              <v-card-text style="height: 300px">
                <div v-if="dataImagenesMarket" class="mt-2">
                  <v-col
                    cols="12"
                    v-for="(index, i) in dataImagenesMarket"
                    :key="i"
                    class="px-2"
                  >
                    <img
                      :src="index.src"
                      width="700px"
                      style="object-fit: contain"
                      alt=""
                    />
                  </v-col>
                </div>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialogImagenesMarket = false"
                >
                  Cerrar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>
      <template>
        <v-row justify="center">
          <v-dialog
            v-model="dialogServiciosDetalles"
            scrollable
            max-width="300px"
          >
            <v-card>
              <v-card-title>Servicios Contratados</v-card-title>
              <v-divider></v-divider>
              <v-card-text style="height: 300px">
                <div
                  v-if="dataServiciosContratados.servicios.length == 0"
                  class="mt-2"
                >
                  <p>--Paquete Basico---</p>
                </div>

                <div
                  v-if="dataServiciosContratados.servicios.length > 0"
                  class="mt-2"
                >
                  <div
                    v-for="(index, i) in dataServiciosContratados.servicios"
                    :key="i"
                  >
                    <p v-if="index">{{ index.value }}</p>
                  </div>
                </div>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialogServiciosDetalles = false"
                >
                  Cerrar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>
      <template>
        <div>
          <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="false"
            :paginate-elements-by-height="1400"
            filename="Contrato-Jardin-La-Pedrera"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="landscape"
            pdf-content-width="auto"
            @progress="onProgress($event)"
            @hasStartedGeneration="hasStartedGeneration()"
            @hasGenerated="hasGenerated($event)"
            ref="html2Pdf"
          >
            <section slot="pdf-content">
              <!-- PDF Content Here -->
              <!--
                        Divide your content into section, this pdf-item will
                        be the element that it's content will not be separated
                        in the paginating process. ex. <h4> and <span> wont be separated.
                    -->

              <!--
                        All other pdf-item will be separated in the pagination process,
                        depending on paginate-elements-by-height prop.
                    -->

              <div style="margin-top: 100px"></div>
              <section class="pdf-item pdf-text">
                <h4>
                  CONTRATO DE PRESTACIÓN DE SERVICIOS QUE CELEBRAN, POR UNA
                  PARTE, JARDÍN LA PEDRERA EN ADELANTE “LA EMPRESA” REPRESENTADO
                  POR DHALMA SAMANTHA CELIS GONZÁLEZ (celular 229 128 7973); Y
                  POR LA OTRA, {{ firstname }}, con clave de elector
                  {{ claveElector }}, con domicilio para efectos de este
                  contrato el ubicado en {{ direccion }}, A QUIEN EN LO SUCESIVO
                  SE LE DENOMINARÁ COMO “CONTRATANTE“, CONFORME AL TENOR DE LAS
                  SIGUIENTES:
                </h4>
              </section>
              <br />
              <section class="pdf-item pdf-text">
                <h4 class="center-text-pdf">CLÁUSULAS</h4>
              </section>
              <br />
              <section class="pdf-item pdf-text">
                <p>
                  <strong>PRIMERA.</strong> LA PARTE “CONTRATANTE” contrata los
                  servicios de “LA EMPRESA”, consistentes en la renta del jardín
                  para eventos ubicado en la calle Sin nombre número 5 interior
                  3, colonia El Porvenir, El Lencero, municipio de Emiliano
                  Zapata en el Estado de Veracruz.
                </p>
              </section>
              <br />
              <section class="pdf-item pdf-text">
                <p>
                  <strong>SEGUNDA.</strong> “LA EMPRESA” se obliga a
                  proporcionar el mobiliario consistente en diez mesas y 100
                  sillas, junto con la renta del inmueble. De igual forma se
                  podrá usar el servicio de baños, cocina (sin gas para cocinar)
                  y alberca de acuerdo con las condiciones contenidas en este
                  contrato.
                </p>
              </section>
              <br />
              <section class="pdf-item pdf-text">
                <p>
                  <strong>TERCERA</strong>. Las partes acuerdan que el evento
                  contratado se celebre en el lugar descrito en la cláusula
                  primera el día {{ reservationDayInWords }}, en un horario de
                  15 a 21 horas, con la precisión que la apertura para los
                  preparativos se realizará a partir de las 11.00 horas, y el
                  cierre del inmueble se realizará a las 21.00 horas, a efecto
                  de que se realicen las labores de limpieza y acomodo del
                  mobiliario.
                </p>
              </section>
              <br />
              <section class="pdf-item pdf-text" v-if="pagarMitad">
                <p>
                  <strong>CUARTA.</strong> “LA EMPRESA” recibirá por concepto de
                  pago, la cantidad de {{ textPayment }} ({{ PaymentInWords }}).
                  El 50% ({{ halfPayment }}) se pagará como anticipo y el resto
                  será cubierto un día antes de empezar con el servicio. El pago
                  será mediante pago en efectivo. En caso de que el evento no se
                  lleve a cabo por parte de la “CONTRATANTE”, no se reembolsará
                  el monto pagado. Solo procederá el cambio de fecha por una
                  ocasión, y previo aviso con 2 semanas de anticipación.
                </p>
              </section>
              <section class="pdf-item pdf-text" v-if="!pagarMitad">
                <p>
                  <strong>CUARTA.</strong> “LA EMPRESA” recibirá por concepto de
                  pago, la cantidad de {{ textPayment }} ({{ PaymentInWords }}).
                  En caso de que el evento no se lleve a cabo por parte de la
                  “CONTRATANTE”, no se reembolsará el monto pagado. Solo
                  procederá el cambio de fecha por una ocasión, y previo aviso
                  con 2 semanas de anticipación.
                </p>
              </section>
              <br />
              <section class="pdf-item pdf-text">
                <p>
                  <strong>QUINTA.</strong> Si el contrato no cumpliera su objeto
                  por cancelación de la parte “CONTRATANTE” se determina como
                  pena convencional el pago del 30% adicional al depósito o
                  apartado realizado a favor de “LA EMPRESA”. Por lo mismo, no
                  será reembolsable cantidad alguna.
                </p>
              </section>
              <br />
              <section class="pdf-item pdf-text">
                <p>
                  <strong>SEXTA.</strong> La parte “CONTRATANTE” se obliga a
                  responder por cualquier desperfecto que ocurra a las
                  instalaciones eléctricas, hidráulicas, de gas y sanitarias.
                  Asimismo, por afectaciones extraordinarias al área de jardín y
                  las plantas que lo conforman, así como al mobiliario que se
                  proporcione con la renta respectiva. El cálculo para la
                  indemnización correspondiente se realizará con el profesional
                  de la rama técnica que corresponda, a partir del presupuesto
                  que se presente al “CONTRATANTE”.
                </p>
              </section>
              <div class="html2pdf__page-break" />
              <div style="margin-top: 100px"></div>
              <br />
              <section class="pdf-item pdf-text">
                <p>
                  <strong>SÉPTIMA.</strong> El área de alberca se entregará
                  limpia y clorada para el uso ordinario de la parte
                  “CONTRATANTE”. La contaminación por residuos orgánicos e
                  inorgánicos de cualquier tipo, será motivo para generar un
                  costo mínimo extraordinario de limpieza de $500.00 (Quinientos
                  pesos 00/100). Asimismo, la parte “CONTRATANTE” asumirá la
                  responsabilidad que se genere por el mal uso de la alberca, ya
                  sea por accidente o negligencia de cuidado en el caso de
                  menores de edad. Por tanto, las partes acuerdan eximir a la
                  empresa de toda responsabilidad penal, administrativa o civil
                  que suscite con motivo de la atención por muerte o lesiones
                  que sufran los usuarios del servicio. De igual forma, se
                  obligan a cubrir los gastos que se generen para sacar a salvo
                  a “LA EMPRESA” de todo procedimiento de investigación, a nivel
                  de juicio y/o procedimiento administrativo.
                </p>
              </section>
              <br />
              <section class="pdf-item pdf-text">
                <p>
                  <strong>OCTAVA.</strong> El cierre de las instalaciones deberá
                  realizarse a las 21 horas y deberá estar en las condiciones
                  generales a las originalmente entregadas, salvo el uso
                  ordinario para cumplir el objeto de este contrato. Como
                  garantía el cliente acepta realizar un depósito de $500.00
                  (Quinientos pesos 00/100) al liquidar el monto del contrato.
                  Mismo que, en su caso, se devolverá al cierre del evento.
                </p>
              </section>
              <br />
              <section class="pdf-item pdf-text">
                <p>
                  <strong>NOVENA.</strong> En caso de que la parte “CONTRATANTE”
                  decida contratar hasta por una hora más el servicio, o por
                  alguna razón se prolongue la apertura del jardín después de la
                  hora límite, se generará un importe extraordinario de $500.00
                  (Quinientos pesos 00/100) y de $600.00 (Seiscientos pesos
                  00/100) más por la segunda. En ningún caso se permitirá la
                  continuación de la apertura después de las 23 horas, por lo
                  que las partes acuerdan el corte del suministro eléctrico sin
                  responsabilidad para ninguna.
                </p>
              </section>
              <br />
              <section class="pdf-item pdf-text">
                <p>
                  <strong>DÉCIMA.</strong> Para la interpretación y cumplimiento
                  del presente contrato, las partes se someten a la jurisdicción
                  y competencia de los tribunales de la Ciudad de Xalapa, del
                  Estado de Veracruz, así como a las disposiciones contenidas en
                  el código Civil vigente para el Estado de Veracruz,
                  renunciando expresamente al fuero que pudiera corresponderles
                  en razón de su domicilio actual o futuro.
                </p>
              </section>
              <br />
              <section class="pdf-item pdf-text">
                <p>
                  Leído el presente contrato y enteradas las partes de su
                  contenido y alcances lo firman por duplicado en la Ciudad de
                  Xalapa de Enríquez, Estado de Veracruz, el dia {{ thisDay }}.
                </p>
              </section>
              <br />
              <br />
              <br />
              <section class="pdf-item pdf-text">
                <v-row>
                  <v-col cols="6">
                    <h1 class="center-text-pdf">“LA EMPRESA”</h1>
                  </v-col>
                  <v-col cols="6">
                    <h1 class="center-text-pdf">“CONTRATANTE”</h1>
                  </v-col>
                </v-row>
              </section>
              <br />
              <section class="pdf-item pdf-text">
                <v-row>
                  <v-col cols="6">
                    <h1 class="center-text-pdf">
                      DHALMA SAMANTHA CELIS GONZÁLEZ
                    </h1>
                  </v-col>
                  <v-col cols="6">
                    <h1 class="center-text-pdf">{{ firstname }}</h1>
                  </v-col>
                </v-row>
              </section>
              <br />
              <br />
              <br />
              <section class="pdf-item pdf-text">
                <v-row>
                  <v-col cols="6">
                    <h1 class="center-text-pdf">_________________________</h1>
                  </v-col>
                  <v-col cols="6">
                    <h1 class="center-text-pdf">_________________________</h1>
                  </v-col>
                </v-row>
              </section>
              <div class="html2pdf__page-break" v-if="pagarMitad" />
              <div style="margin-top: 100px" v-if="pagarMitad"></div>
              <section class="pdf-item pdf-text" v-if="pagarMitad">
                <p>
                  Por el presente pagaré reconozco y me obligo a pagar en esta
                  Ciudad o en cualquier otra parte donde se me requiera a la
                  orden de José Oliveros Ruiz, la cantidad de {{ halfPayment }},
                  el día {{ reservationDayInWords }} Cantidad que deberá pagarse
                  y recibirse en efectivo a mi entera satisfacción. La referida
                  cantidad forma parte de una mayor, por ende, queda
                  expresamente convenido que si no es pagado este documento
                  precisamente a su vencimiento, su incumplimiento queda sujeto
                  a las disposiciones legales vigentes. Este pagaré es mercantil
                  y está regido por la Ley General de Títulos y Operaciones de
                  Crédito, en su artículo 173, parte final, así como los
                  correlativos por no ser pagaré domiciliado. De no verificarse
                  el pago de la cantidad que este pagaré contiene, el día de su
                  vencimiento, se generará el rédito del 15% mensual, por todo
                  el tiempo que este insoluto, sin perjuicio de los gastos y
                  costas que se generan por el cobro del mismo.
                </p>
              </section>
              <br />
              <br />
              <br />
              <section class="pdf-item pdf-text" v-if="pagarMitad">
                <v-row>
                  <v-col cols="6">
                    <h1 class="center-text-pdf">“LA EMPRESA”</h1>
                  </v-col>
                  <v-col cols="6">
                    <h1 class="center-text-pdf">“CONTRATANTE”</h1>
                  </v-col>
                </v-row>
              </section>
              <br />
              <section class="pdf-item pdf-text" v-if="pagarMitad">
                <v-row>
                  <v-col cols="6">
                    <h1 class="center-text-pdf">
                      DHALMA SAMANTHA CELIS GONZÁLEZ
                    </h1>
                  </v-col>
                  <v-col cols="6">
                    <h1 class="center-text-pdf">{{ firstname }}</h1>
                  </v-col>
                </v-row>
              </section>
              <br />
              <br />
              <br />
              <section class="pdf-item pdf-text" v-if="pagarMitad">
                <v-row>
                  <v-col cols="6">
                    <h1 class="center-text-pdf">_________________________</h1>
                  </v-col>
                  <v-col cols="6">
                    <h1 class="center-text-pdf">_________________________</h1>
                  </v-col>
                </v-row>
              </section>

              <!--
                        If you have any image with a remote source
                        set html2canvas.useCORS to true, although it is set to true by default
                        Ex.
                        html2canvas: {
                            useCORS: true
                        }
                    -->
              <!-- <section class="pdf-item">
                        <img :src="remoteImageLink">
                    </section> -->
            </section>
          </vue-html2pdf>
        </div>
      </template>

      <FooterOne />
    </v-app>
  </div>
</template>
<script src="https://cdn.jsdelivr.net/npm/vue@2.x/dist/vue.js"></script>
<script src="https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.js"></script>
<script>
// @ is an alias to /src
import { COLORS } from "@/assets/theme/colors.js";
import navbar from "../components/navbar/navbar.vue";

import Gallery from "../components/home/galeria/galeria.vue";
import FooterOne from "../components/footer/footer.vue";

import swal from "prettyalert";
import VueHtml2pdf from "vue-html2pdf";
import format from "date-fns/format";

import moment from "moment";

import { getAuth } from "firebase/auth";

import {
  collection,
  query,
  where,
  getDocs,
  getFirestore,
  doc,
  addDoc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";

export default {
  name: "Home",
  components: {
    navbar,
    Gallery,
    FooterOne,
    VueHtml2pdf,
  },
  methods: {
    tryReservation() {
      const auth = getAuth();
      if (auth.currentUser) {
        //GO TO RESERVATION
        this.$router.push({ name: "reservacion" });
      } else {
        //NO USER
        console.log("NO USER");
        this.noUserDialog = true;
      }
    },

    showLocation(item) {
      this.dialogUbicacion = true;
      this.ubicacion = item;
      console.log(item);
    },

    clearFechaApartar() {
      this.fechaApartar = "";
      this.nombreReserva = "";
      this.tipoPagoReserva = null;
      this.serviciosApartar = [];
      this.serviciosInfo = [];
    },

    setPago() {
      this.pagarMitad = !this.pagarMitad;
      this.pagarEntero = !this.pagarEntero;
    },

    validateInfo() {
      let formattedDates = "";
      formattedDates = format(this.reservationDay, this.dateFormat);
      let event = new Date(formattedDates);
      var options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      var newformattedDates = event.toLocaleString("es-ES", options);
      this.reservationDayInWords = newformattedDates;

      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

      var convertir = require("numero-a-letras");
      this.PaymentInWords = convertir.NumerosALetras(this.totalPrecio);

      const halfpay = this.totalPrecio / 2;
      this.halfPayment = formatter.format(halfpay);
      this.testText = formatter.format(this.totalPrecio);
      this.textPayment = this.testText + " MXN";

      const d = new Date();
      this.thisDay =
        d.getDate().toString() +
        "-" +
        d.getMonth().toString() +
        "-" +
        d.getFullYear().toString();

      if (this.pagarMitad) {
        this.toPay = this.totalPrecio / 2;
      } else {
        this.toPay = this.totalPrecio;
      }

      if (!this.valid) {
        swal(
          "Datos Incorrectos",
          "Algunos datos son incorrectos o no estan llenos por favor revisa nuevamente",
          "error"
        );
      } else {
        this.firstname = this.firstname.toUpperCase();
        this.claveElector = this.claveElector.toUpperCase();

        console.log("Nombre: ", this.firstname);
        console.log("claveElector: ", this.claveElector);
        console.log("direccion: ", this.direccion);
        console.log("email: ", this.email);
        console.log("reservationDay: ", this.reservationDay);
        console.log("totalPrecio: ", this.totalPrecio);
        console.log("pagarEntero: ", this.pagarEntero);
        console.log("pagarMitad: ", this.pagarMitad);
        console.log("reservationDayInWords: ", this.reservationDayInWords);
        console.log("textPayment: ", this.textPayment);
        console.log("PaymentInWords: ", this.PaymentInWords);
        console.log("halfPayment: ", this.halfPayment);
        console.log("thisDay: ", this.thisDay);

        this.generateReport();
      }
    },

    showServicios(item) {
      this.dialogServiciosMarket = true;
      this.dataServiciosMarket = item;
    },

    showImages(item) {
      this.dialogImagenesMarket = true;
      this.dataImagenesMarket = item;
    },

    async aproveMarket(item) {
      console.log(item);
      const db = getFirestore();
      const docReference = doc(db, "marketplace", item.tempId);
      // Set the "capital" field of the city 'DC'
      await updateDoc(docReference, {
        aprovado: item.aprovado,
      });
    },

    async apartarFecha() {
      this.serviciosApartar.forEach((element) => {
        this.serviciosInfo.push(this.serviciosDescrip[element]);
      });

      if (
        this.fechaApartar != "" &&
        this.nombreReserva != "" &&
        this.tipoPagoReserva != null
      ) {
        const db = getFirestore();
        await addDoc(collection(db, "reservacionesFechas"), {
          fecha: this.fechaApartar,
          usuario: this.nombreReserva,
          uid: this.usuario.uid,
          servicios: this.serviciosInfo,
          pagoMitad: this.tipoPagoReserva,
        });

        swal({
          title: "Fecha Apartada",
          text: "La fecha " + this.fechaApartar + " ha sido apartada",
          icon: "success",
        }).then(() => {
          this.obtenerFechas();
          this.clearFechaApartar();
        });
      } else {
        swal({
          title: "Completa los datos",
          text: "Por favor llena todos los datos",
          icon: "warning",
        }).then(function () {
          //window.location.href = window.location.hostname;
          //router.push({ name: 'Home'})
        });
      }
    },

    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },

    async liberarFecha() {
      console.log(this.fechaLiberar);
      if (this.fechaLiberar != "") {
        const db = getFirestore();
        const q = query(
          collection(db, "reservacionesFechas"),
          where("fecha", "==", this.fechaLiberar)
        );
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          //console.log(doc.id, " => ", doc.data());
          this.deleteReference.push(doc.id);
        });

        console.log(this.deleteReference);

        this.deleteReference.forEach((element) => {
          deleteDoc(doc(db, "reservacionesFechas", element));
        });

        swal({
          title: "Fecha Liberada",
          text: "La fecha " + this.fechaLiberar + " ha sido liberada",
          icon: "success",
        }).then(() => {
          this.deleteReference = [];
          this.fechaLiberar = "";
          this.obtenerFechas();
        });
      } else {
        swal({
          title: "Completa los datos",
          text: "Por favor llena todos los datos",
          icon: "warning",
        }).then(function () {
          //window.location.href = window.location.hostname;
          //router.push({ name: 'Home'})
        });
      }
    },

    async updateAproved(item) {
      console.log("ima change the doc to aproved :", item);

      const db = getFirestore();
      const docReference = doc(db, "reservacionesFechas", item.id);
      // Set the "capital" field of the city 'DC'
      if (item.fechaApartadaExpirada != null) {
        await updateDoc(docReference, {
          status: "Pagado",
          fecha: item.fechaApartadaExpirada,
        });
      } else {
        await updateDoc(docReference, {
          status: "Pagado",
        });
      }

      this.obtenerFechas();
    },

    async updateUnaproved(item) {
      const db = getFirestore();
      const docReference = doc(db, "reservacionesFechas", item.id);
      // Set the "capital" field of the city 'DC'
      updateDoc(docReference, {
        fecha: null,
        status: "Cancelado",
        fechaApartadaExpirada: item.fecha,
      });
      this.obtenerFechas();
    },

    async obtenerFechas() {
      this.datosFechas = [];
      this.usuario = JSON.parse(sessionStorage.getItem("usuario"));
      const db = getFirestore();
      const q = query(collection(db, "reservacionesFechas"));

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((docu) => {
        // doc.data() is never undefined for query doc snapshots
        let tempData = docu.data();
        if (tempData.pagoMitad == null) {
          tempData.pagoMitad = "-------------";
        } else if (tempData.pagoMitad == true) {
          tempData.pagoMitad = "Pago Mitad";
        } else {
          tempData.pagoMitad = "Pago Completo";
        }

        if (tempData.fechaCaducidad != null) {
          const d = new Date();
          let time = d.getTime();

          let status = "";

          let tempFecha = moment(tempData.fechaCaducidad).format(
            "YYYY[-]MM[-]DD"
          );

          if (tempData.status == "Pagado") {
            status = "Pagado";
            tempData["statusColor"] = "#2E7D32";
          } else {
            if (tempData.status == "Cancelado") {
              status = "Cancelado";
              tempData["statusColor"] = "#F44336";
            } else {
              if (tempData.fechaCaducidad < time) {
                status = "Expirado";
                tempData["statusColor"] = "#F44336";

                const db = getFirestore();
                const docReference = doc(db, "reservacionesFechas", docu.id);
                // Set the "capital" field of the city 'DC'
                updateDoc(docReference, {
                  fecha: null,
                  status: "Expirado",
                  fechaApartadaExpirada: tempData.fecha,
                });
              } else {
                status = "No Pagado";
                tempData["statusColor"] = "#9E9E9E";
              }
            }
          }
          tempData["tempFecha"] = tempFecha;
          tempData["status"] = status;
        }
        tempData["id"] = docu.id;

        this.datosFechas.push(tempData);
      });

      console.log("Los datos de las fechas: ", this.datosFechas);
    },

    async obtenerCitas() {
      this.datosCitas = [];
      const db = getFirestore();
      const q = query(collection(db, "citas"));

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        this.datosCitas.push(doc.data());
      });
    },

    async obtenerMarket() {
      this.datosMarket = [];

      const db = getFirestore();
      const q = query(collection(db, "marketplace"));

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        let tempData = doc.data();
        let tempId = doc.id;
        let tempInfo = { ...tempData, tempId };
        this.datosMarket.push(tempInfo);
      });
    },

    showDetails(item) {
      this.dialogServiciosDetalles = true;
      this.dataServiciosContratados = item;
      console.log(this.dataServiciosContratados);
    },
  },
  mounted() {
    this.obtenerFechas();
    this.obtenerMarket();
    this.obtenerCitas();
  },
  data() {
    return {
      valid: false,
      colors: COLORS,
      noUserDialog: false,
      usuario: [],
      fechaLiberar: "",
      fechaApartar: "",
      nombreReserva: "",
      tipoPagoReserva: null,
      tipoPagos: [
        { text: "Completo", value: false },
        { text: "Mitad", value: true },
      ],

      deleteReference: [],

      datosFechas: [],
      headers: [
        {
          text: "Fecha de la Reservacion",
          align: "start",
          value: "fecha",
        },
        {
          text: "Fecha de Caducidad Pago",
          value: "tempFecha",
        },
        {
          text: "Codigo",
          value: "codigoDeposito",
        },
        { text: "Reservo", value: "usuario" },
        { text: "Pago", value: "pagoMitad" },
        { text: "Detalles", value: "actions" },
      ],

      citasHeaders: [
        {
          text: "Fecha de la Cita",
          align: "start",
          value: "start",
        },
        { text: "Reservo", value: "name" },
        { text: "Telefono", value: "telefono" },
      ],

      datosCitas: [],

      headers2: [
        {
          text: "Establecimiento",
          align: "start",
          value: "nombreEstablecimiento",
        },
        { text: "Descripcion", value: "descripcion" },
        { text: "Facebook", value: "redSocialFacebook" },
        { text: "Instagram", value: "redSocialIstagram" },
        { text: "Whatsapp", value: "redSocialWhatsapp" },
        { text: "Mas Informacion", value: "actions" },
        { text: "Aprovado", value: "aprovado" },
      ],

      dialogServiciosDetalles: false,
      dataServiciosContratados: {
        servicios: [],
      },

      itemServicios: [
        {
          id: 0,
          state: false,
          text: "Show de payasos",
          value: "0",
          price: 3250,
          absolutePlace: 0,
          descrip: "Show de payasos para niños con concursos y premios.",
          img: "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FServicios%2FEntretenimiento%2Fentretenimiento1.jpg?alt=media&token=4c651b69-a23a-457f-afb2-0fd237104987",
        },
        //{ id: 1 , state: false , value: "Globoflexia", price: 5200, absolutePlace: 1 , descrip:"" , img: "" },
        {
          id: 1,
          state: false,
          text: "Magia",
          value: "1",
          price: 5200,
          absolutePlace: 1,
          descrip:
            "Show de magia clásica, se presentan diferentes efectos como bastones, palomas, plumas, juegos de magia hablados e interactuados.",
          img: "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FServicios%2FEntretenimiento%2Fentre-magia.jpg?alt=media&token=074b0c5d-c5cd-4570-865c-598bd2aae3e4",
        },
        {
          id: 2,
          state: false,
          text: "Pinta caritas",
          value: "2",
          price: 1800,
          absolutePlace: 2,
          descrip:
            "Servicio de pinta caritas con diferentes diseños para niños.",
          img: "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FServicios%2FEntretenimiento%2Fentre-pintacaritas.jpg?alt=media&token=209154d7-e0f5-4c33-9598-9312347d2c5a",
        },
        {
          id: 3,
          state: false,
          text: "Botargas",
          value: "3",
          price: 2500,
          absolutePlace: 3,
          descrip:
            "Botargas 300 botargas diferentes, concursos, coreografía, coordinación de pastel y piñatas, micrófono inalámbrico, 3 horas de servicio 2 personas.",
          img: "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FServicios%2FEntretenimiento%2Fentre-botargas.jpg?alt=media&token=82ca4c89-0aa0-4a3b-9943-8d04a59b4495",
        },
        {
          id: 4,
          state: false,
          text: "Inflables",
          value: "4",
          price: 1100,
          absolutePlace: 4,
          descrip: "Inflables diferentes tamaños grandes, medianos, y chicos. ",
          img: "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FServicios%2FEntretenimiento%2Fentre-inflables.jpg?alt=media&token=a611a78d-fe83-4dfd-96be-eee4b70a6a81",
        },
        {
          id: 5,
          state: false,
          text: "Dj",
          value: "5",
          price: 4000,
          absolutePlace: 5,
          descrip:
            "6 horas de servicio, con distinto equipamiento de acuerdo con las necesidades amenización de comida y baile. ",
          img: "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FServicios%2FEntretenimiento%2Fentre-dj.jpg?alt=media&token=f9104fd8-cc7b-496c-a037-6e5f49e35501",
        },

        {
          id: 10,
          state: false,
          text: "Taquiza",
          value: "6",
          price: 5000,
          absolutePlace: 10,
          descrip:
            "Se proporcionan de 1 a 2 órdenes de tacos acompañados de salsas, vegetales, desechables, limones, etc. Y personal asistiendo evento.",
          img: "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FServicios%2FComida%2Fcomida-taquiza.jpg?alt=media&token=562a3ffb-0201-477e-84c7-2c885a10a9f5",
        },
        //{ id: 11 , state: false , value: "Platillo Infantil", price: 200, absolutePlace: 11 , descrip:"" , img: ""},
        //{ id: 12 , state: false , value: "Cazuelas", price: 1500, absolutePlace: 12 , descrip:"Servicio de cazuela de guisados; 5 guisados diferentes, 2 guarniciones a elegir, 2 vitroleras de agua, personal para servir. " , img: ""},
        {
          id: 11,
          state: false,
          text: "Pastel",
          value: "7",
          price: 1500,
          absolutePlace: 11,
          descrip:
            "Pastel con diferentes diseños, diferentes rellenos, personalizados para 50 o 110 personas. ",
          img: "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FServicios%2FComida%2Fcomida-pastel.jpg?alt=media&token=afce1b17-6497-42ca-87a7-f62849885538",
        },
        //{ id: 14 , state: false , value: "Gelatina", price: 544, absolutePlace: 14 , descrip:"" , img: ""},
        {
          id: 12,
          state: false,
          text: "Mesa de Dulces",
          value: "8",
          price: 3200,
          absolutePlace: 12,
          descrip:
            "Mesa de dulces, personalizadas dependiendo de la temática, dulces variados y frutas de temporadas. ",
          img: "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FServicios%2FComida%2Fcomida-mesa-dulces.jpg?alt=media&token=857fd461-0066-4838-8380-fdca65e70131",
        },
        {
          id: 13,
          state: false,
          text: "Refrescos o Aguas",
          value: "9",
          price: 3500,
          absolutePlace: 13,
          descrip:
            "Servicio de refresco ilimitado para todo tu evento dependiendo el numero de personas. ",
          img: "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FServicios%2FComida%2Fcomida-refrescos.jpg?alt=media&token=c033b526-7126-4ff1-b76e-7eb855c618f9",
        },

        //{ id: 20 , state: false , value: "Mesas", price: 986, absolutePlace: 20 , descrip:"" , img: ""},
        //{ id: 21 , state: false , value: "Sillas", price: 876, absolutePlace: 21 , descrip:"" , img: ""},
        {
          id: 22,
          state: false,
          text: "Mantelería",
          value: "10",
          price: 60,
          absolutePlace: 22,
          descrip: "",
          img: "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FServicios%2FMobiliario%2Fmobiliario-manteleria.jpg?alt=media&token=abfdcca0-7295-458c-95f9-188dc540f605",
        },
        //{ id: 23 , state: false , value: "Loza", price: 764, absolutePlace: 23 , descrip:"" , img: ""},
        {
          id: 30,
          state: false,
          text: "Globos",
          value: "11",
          price: 1300,
          absolutePlace: 30,
          descrip:
            "Diferentes modelos de decoración y materiales de acuerdo a la temática de su evento",
          img: "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FServicios%2FDecoracion%2Fdecoracion-globos.jpg?alt=media&token=1ab45298-0e1f-469a-b3ea-3ddef9e67b05",
        },
        {
          id: 31,
          state: false,
          text: "Piñatas",
          value: "12",
          price: 250,
          absolutePlace: 31,
          descrip: "Piñatas todas las temáticas diferentes tamaños y costos. ",
          img: "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FServicios%2FDecoracion%2Fdecoracion-pinatas.jpg?alt=media&token=5bcd8e18-4175-47e4-9df8-a08a5dca1f19",
        },
        {
          id: 32,
          state: false,
          text: "Centros De Mesa",
          value: "13",
          price: 100,
          absolutePlace: 32,
          descrip: "",
          img: "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FServicios%2FDecoracion%2Fdecoracion-centros-mesa.jpg?alt=media&token=d5222759-d9eb-4118-b366-ad9023757c4f",
        },

        {
          id: 40,
          state: false,
          text: "Meseros",
          value: "14",
          price: 0,
          absolutePlace: 40,
          descrip: "",
          img: "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FServicios%2FPersonal-Apoyo%2FPersonal-meseros.jpg?alt=media&token=e55a50a1-1a43-42a8-93fd-59287a86a862",
        },
        {
          id: 41,
          state: false,
          text: "Hostess",
          value: "15",
          price: 0,
          absolutePlace: 41,
          descrip: "",
          img: "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FServicios%2FPersonal-Apoyo%2FPersonal-meseros.jpg?alt=media&token=e55a50a1-1a43-42a8-93fd-59287a86a862",
        },
        {
          id: 42,
          state: false,
          text: "Valet Parking",
          value: "16",
          price: 0,
          absolutePlace: 42,
          descrip: "",
          img: "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FServicios%2FPersonal-Apoyo%2FPersonal-meseros.jpg?alt=media&token=e55a50a1-1a43-42a8-93fd-59287a86a862",
        },
      ],

      serviciosDescrip: [
        {
          id: 0,
          state: false,
          text: "Show de payasos",
          value: "Show de payasos",
          price: 3250,
          absolutePlace: 0,
          descrip: "Show de payasos para niños con concursos y premios.",
          img: "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FServicios%2FEntretenimiento%2Fentretenimiento1.jpg?alt=media&token=4c651b69-a23a-457f-afb2-0fd237104987",
        },
        //{ id: 1 , state: false , value: "Globoflexia", price: 5200, absolutePlace: 1 , descrip:"" , img: "" },
        {
          id: 1,
          state: false,
          text: "Magia",
          value: "Magia",
          price: 5200,
          absolutePlace: 1,
          descrip:
            "Show de magia clásica, se presentan diferentes efectos como bastones, palomas, plumas, juegos de magia hablados e interactuados.",
          img: "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FServicios%2FEntretenimiento%2Fentre-magia.jpg?alt=media&token=074b0c5d-c5cd-4570-865c-598bd2aae3e4",
        },
        {
          id: 2,
          state: false,
          text: "Pinta caritas",
          value: "Pinta caritas",
          price: 1800,
          absolutePlace: 2,
          descrip:
            "Servicio de pinta caritas con diferentes diseños para niños.",
          img: "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FServicios%2FEntretenimiento%2Fentre-pintacaritas.jpg?alt=media&token=209154d7-e0f5-4c33-9598-9312347d2c5a",
        },
        {
          id: 3,
          state: false,
          text: "Botargas",
          value: "Botargas",
          price: 2500,
          absolutePlace: 3,
          descrip:
            "Botargas 300 botargas diferentes, concursos, coreografía, coordinación de pastel y piñatas, micrófono inalámbrico, 3 horas de servicio 2 personas.",
          img: "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FServicios%2FEntretenimiento%2Fentre-botargas.jpg?alt=media&token=82ca4c89-0aa0-4a3b-9943-8d04a59b4495",
        },
        {
          id: 4,
          state: false,
          text: "Inflables",
          value: "Inflables",
          price: 1100,
          absolutePlace: 4,
          descrip: "Inflables diferentes tamaños grandes, medianos, y chicos. ",
          img: "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FServicios%2FEntretenimiento%2Fentre-inflables.jpg?alt=media&token=a611a78d-fe83-4dfd-96be-eee4b70a6a81",
        },
        {
          id: 5,
          state: false,
          text: "Dj",
          value: "Dj",
          price: 4000,
          absolutePlace: 5,
          descrip:
            "6 horas de servicio, con distinto equipamiento de acuerdo con las necesidades amenización de comida y baile. ",
          img: "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FServicios%2FEntretenimiento%2Fentre-dj.jpg?alt=media&token=f9104fd8-cc7b-496c-a037-6e5f49e35501",
        },

        {
          id: 10,
          state: false,
          text: "Taquiza",
          value: "Taquiza",
          price: 5000,
          absolutePlace: 10,
          descrip:
            "Se proporcionan de 1 a 2 órdenes de tacos acompañados de salsas, vegetales, desechables, limones, etc. Y personal asistiendo evento.",
          img: "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FServicios%2FComida%2Fcomida-taquiza.jpg?alt=media&token=562a3ffb-0201-477e-84c7-2c885a10a9f5",
        },
        //{ id: 11 , state: false , value: "Platillo Infantil", price: 200, absolutePlace: 11 , descrip:"" , img: ""},
        //{ id: 12 , state: false , value: "Cazuelas", price: 1500, absolutePlace: 12 , descrip:"Servicio de cazuela de guisados; 5 guisados diferentes, 2 guarniciones a elegir, 2 vitroleras de agua, personal para servir. " , img: ""},
        {
          id: 11,
          state: false,
          text: "Pastel",
          value: "Pastel",
          price: 1500,
          absolutePlace: 11,
          descrip:
            "Pastel con diferentes diseños, diferentes rellenos, personalizados para 50 o 110 personas. ",
          img: "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FServicios%2FComida%2Fcomida-pastel.jpg?alt=media&token=afce1b17-6497-42ca-87a7-f62849885538",
        },
        //{ id: 14 , state: false , value: "Gelatina", price: 544, absolutePlace: 14 , descrip:"" , img: ""},
        {
          id: 12,
          state: false,
          text: "Mesa de Dulces",
          value: "Mesa de Dulces",
          price: 3200,
          absolutePlace: 12,
          descrip:
            "Mesa de dulces, personalizadas dependiendo de la temática, dulces variados y frutas de temporadas. ",
          img: "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FServicios%2FComida%2Fcomida-mesa-dulces.jpg?alt=media&token=857fd461-0066-4838-8380-fdca65e70131",
        },
        {
          id: 13,
          state: false,
          text: "Refrescos o Aguas",
          value: "Refrescos o Aguas",
          price: 3500,
          absolutePlace: 13,
          descrip:
            "Servicio de refresco ilimitado para todo tu evento dependiendo el numero de personas. ",
          img: "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FServicios%2FComida%2Fcomida-refrescos.jpg?alt=media&token=c033b526-7126-4ff1-b76e-7eb855c618f9",
        },

        //{ id: 20 , state: false , value: "Mesas", price: 986, absolutePlace: 20 , descrip:"" , img: ""},
        //{ id: 21 , state: false , value: "Sillas", price: 876, absolutePlace: 21 , descrip:"" , img: ""},
        {
          id: 22,
          state: false,
          text: "Mantelería",
          value: "Mantelería",
          price: 60,
          absolutePlace: 22,
          descrip: "",
          img: "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FServicios%2FMobiliario%2Fmobiliario-manteleria.jpg?alt=media&token=abfdcca0-7295-458c-95f9-188dc540f605",
        },
        //{ id: 23 , state: false , value: "Loza", price: 764, absolutePlace: 23 , descrip:"" , img: ""},
        {
          id: 30,
          state: false,
          text: "Globos",
          value: "Globos",
          price: 1300,
          absolutePlace: 30,
          descrip:
            "Diferentes modelos de decoración y materiales de acuerdo a la temática de su evento",
          img: "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FServicios%2FDecoracion%2Fdecoracion-globos.jpg?alt=media&token=1ab45298-0e1f-469a-b3ea-3ddef9e67b05",
        },
        {
          id: 31,
          state: false,
          text: "Piñatas",
          value: "Piñatas",
          price: 250,
          absolutePlace: 31,
          descrip: "Piñatas todas las temáticas diferentes tamaños y costos. ",
          img: "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FServicios%2FDecoracion%2Fdecoracion-pinatas.jpg?alt=media&token=5bcd8e18-4175-47e4-9df8-a08a5dca1f19",
        },
        {
          id: 32,
          state: false,
          text: "Centros De Mesa",
          value: "Centros De Mesa",
          price: 100,
          absolutePlace: 32,
          descrip: "",
          img: "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FServicios%2FDecoracion%2Fdecoracion-centros-mesa.jpg?alt=media&token=d5222759-d9eb-4118-b366-ad9023757c4f",
        },

        {
          id: 40,
          state: false,
          text: "Meseros",
          value: "Meseros",
          price: 0,
          absolutePlace: 40,
          descrip: "",
          img: "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FServicios%2FPersonal-Apoyo%2FPersonal-meseros.jpg?alt=media&token=e55a50a1-1a43-42a8-93fd-59287a86a862",
        },
        {
          id: 41,
          state: false,
          text: "Hostess",
          value: "Hostess",
          price: 0,
          absolutePlace: 41,
          descrip: "",
          img: "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FServicios%2FPersonal-Apoyo%2FPersonal-meseros.jpg?alt=media&token=e55a50a1-1a43-42a8-93fd-59287a86a862",
        },
        {
          id: 42,
          state: false,
          text: "Valet Parking",
          value: "Valet Parking",
          price: 0,
          absolutePlace: 42,
          descrip: "",
          img: "https://firebasestorage.googleapis.com/v0/b/pedreragarden.appspot.com/o/Media%2FServicios%2FPersonal-Apoyo%2FPersonal-meseros.jpg?alt=media&token=e55a50a1-1a43-42a8-93fd-59287a86a862",
        },
      ],
      serviciosApartar: [],
      serviciosInfo: [],

      nameRules: [
        (v) => !!v || "Escriba su nombre completo",
        (v) => v.length <= 50 || "El nombre debe ser menor a 50 caracteres",
      ],
      claveRules: [
        (v) => !!v || "Escriba su clave de elector",
        (v) => v.length >= 5 || "La clave de elector debe ser valida",
      ],
      direccionRules: [
        (v) => !!v || "Escriba su direccion de elector",
        (v) => v.length >= 10 || "La direccion debe ser valida",
      ],
      email: "",
      emailRules: [
        (v) => !!v || "El correo no es valido",
        (v) => /.+@.+/.test(v) || "El correo no es valido",
      ],

      dateFormat: "dddd, MMMM D, YYYY",
      firstname: "",
      claveElector: "",
      direccion: "",
      email: "",
      reservationDay: "",
      totalPrecio: "",
      pagarEntero: true,
      pagarMitad: false,
      reservationDayInWords: "",

      textPayment: "",
      PaymentInWords: "",
      halfPayment: "",
      thisDay: "",

      datosMarket: [],

      dataServiciosMarket: null,
      dialogServiciosMarket: false,
      dialogImagenesMarket: false,
      dataImagenesMarket: null,

      dialogUbicacion: false,
      ubicacion: null,
    };
  },
};
</script>

<style scoped>
.google-map {
  width: 100% !important;
  height: 450px !important;
}
.card-title-text {
  font-family: "Kanit", sans-serif;
  font-size: 1.4rem;
}

.background-style {
  background-image: url("../assets/media/bgImg.png");
}
.left-side {
  min-height: 300px;
  background: rgba(73, 252, 212, 0.8);
}
.right-side {
  min-height: 300px;
  background: #fff;
}
.title-resume {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 4rem;
  font-weight: 700;
  letter-spacing: -0.05em;
  line-height: 1.1;
  margin: 0 0 1.5rem 0;
  text-transform: none;
}
.center-text-vertical {
  margin-top: 80px;
  margin-bottom: 100px;
  margin-left: 10%;
  margin-right: 10%;
}

.center-text-vertical-left {
  margin-top: 230px;
  margin-bottom: 100px;
  margin-left: 10%;
  margin-right: 10%;
}

.hidden-on-desktop {
  display: none;
}

.payment-spacer {
  margin-top: 300px;
}

.right-resume-title {
  font-family: "Kanit", sans-serif;
  font-size: 2.2rem;
  font-weight: 800;
}

.right-contract-title {
  font-family: "Kanit", sans-serif;
  font-size: 2.2rem;
  font-weight: 800;
}

.contract-right-side {
  margin-top: 80px;
}

.right-resume-subtitle {
  font-family: "Kanit", sans-serif;
  font-size: 1.8rem;
  font-weight: 300;
}

.right-resume-text {
  font-family: "Kanit", sans-serif;
  font-size: 1rem;
  font-weight: 300;
  margin-left: 20px;
}

.right-resume-text-other {
  font-family: "Kanit", sans-serif;
  font-size: 1rem;
  font-weight: 300;
  margin-left: 20px;
}

.right-resume-text-other p {
  margin-bottom: 1px !important;
}

.btn-siguiente {
  margin-top: 100px;
}

.title-btn-siguiente {
  font-family: "Kanit", sans-serif;
  font-weight: 900;
  font-size: 1.5rem;
  color: #271f30;
}

.payment-text {
  font-family: "Kanit", sans-serif;
  font-weight: 800;
  font-size: 1.5rem;
  color: #271f30;
}

.payment-number {
  font-family: "Kanit", sans-serif;
  font-weight: 400;
  font-size: 1.2rem;
  color: #271f30;
  margin-left: 10px;
}

.payment-text-words {
  font-family: "Kanit", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  color: #271f30;
}

.no-extras-text {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  color: darkslategrey;
}

.date-text {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1rem;
  font-weight: 800;
  color: #271f30;
}

.date-text-date {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
  color: #271f30;
}

.pdf-text {
  margin-left: 100px;
  margin-right: 100px;
  text-align: justify;
}
.center-text-pdf {
  text-align: center !important;
}
</style>
